<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <base-toggle-sidebar-button />
        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
        >
            <div
                class="mx-auto flex flex-col px-4 sm:px-6 md:flex-row md:items-center md:justify-between lg:px-8"
            >
                <BasePageTitle title="Orders" />

                <div class="flex items-center">
                    <div class="flex items-center">
                        <div class="mr-2 mt-1 inline-flex sm:mt-0">
                            <export-button @clicked="showOrderExportModal" />
                        </div>
                        <div
                            class="mr-5 inline-flex flex-row items-center rounded-md border border-gray-300 bg-white px-3 py-1.5 shadow-sm md:mb-0"
                        >
                            <span class="mr-3 text-xs font-semibold text-gray-700"
                                >Group by date
                            </span>
                            <base-small-toggle-switch
                                :value="userSettings.byDate"
                                @input="toggleGroupByDate()"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col md:flex-row">
                        <div
                            class="mt-3 h-4 w-4"
                            :class="{
                                'text-gray-900 spinner': isLoading
                            }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-auto sm:px-6 md:px-8">
                <div class="py-4">
                    <div class="rounded-lg bg-white shadow">
                        <div>
                            <div
                                class="flex flex-col justify-between px-2 py-2.5 sm:flex-row sm:px-6"
                            >
                                <ul
                                    class="mb-2 flex flex-row items-center sm:mb-0"
                                >
                                    <li
                                        class="mr-1 cursor-pointer rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50"
                                        :class="{
                                            'bg-gray-100':
                                                filter.key === selectedFilter
                                        }"
                                        v-for="filter in dateFilters"
                                        :key="filter.key"
                                        @click="applyDateFilter(filter.key)"
                                    >
                                        {{ filter.label }}
                                    </li>
                                </ul>
                                <div
                                    v-if="selectedRows.length"
                                    class="mb-3 ml-5 mr-auto inline-flex flex-col md:mb-0"
                                >
                                    <span
                                        class="relative z-0 inline-flex rounded-md shadow-sm"
                                    >
                                        <span
                                            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700"
                                        >
                                            {{ selectedRows.length }}
                                            selected
                                        </span>
                                        <button
                                            type="button"
                                            @click="acceptOrders"
                                            :disabled="isLoading"
                                            class="focus:outline-none relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                        >
                                            Accept ({{
                                                selectedPendingRows.length
                                            }})
                                        </button>
                                        <button
                                            type="button"
                                            @click="bulkPrintOrders"
                                            :disabled="isLoading"
                                            class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                                        >
                                            Print ({{ selectedRows.length }})
                                        </button>
                                    </span>
                                </div>

                                <div class="mr-4 flex items-center">
                                    <search-input
                                        class="text-gray-700 sm:mr-3"
                                        size="sm"
                                        @input="searchById"
                                        :value="serverParams.search"
                                        :min="3"
                                        :max="9"
                                        placeholder="Search by order code"
                                    />
                                    <base-button
                                        @clicked="openFilterSidebar"
                                        size="sm"
                                        type="button"
                                        :class="
                                            selectedFilters
                                                ? 'border-indigo-600 text-indigo-600'
                                                : 'border-gray-300 text-gray-700'
                                        "
                                        :hightlighted="!!selectedFilters"
                                        :button-text="
                                            selectedFilters
                                                ? `${selectedFilters} Filters`
                                                : 'Filters'
                                        "
                                    />
                                </div>
                            </div>
                            <div
                                v-if="hasNewOrders"
                                :class="{
                                    'opacity-75': isLoading
                                }"
                                class="w-full bg-indigo-900 px-4 py-2 text-sm text-white"
                            >
                                <span class="font-bold">
                                    {{ hasNewOrders }} new order{{
                                        hasNewOrders > 1 ? 's' : ''
                                    }},
                                </span>
                                <span
                                    @click="fetchOrders()"
                                    class="cursor-pointer underline"
                                    >click here to fetch them</span
                                >
                            </div>
                            <vue-good-table
                                ref="vueGoodTable"
                                mode="remote"
                                class="border-b border-gray-200"
                                styleClass="tailwind-table"
                                @on-selected-rows-change="changeSelectedRows"
                                @on-row-click="navigateToOrder"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-per-page-change="onPerPageChange"
                                :isLoading.sync="isLoading"
                                :totalRows="ordersLength"
                                :pagination-options="{
                                    enabled: true,
                                    perPage:
                                        parseInt($route.query.limit) ||
                                        userSettings.limit ||
                                        serverParams.limit,
                                    dropdownAllowAll: false,
                                    rowsPerPageLabel: '',
                                    perPageDropdown: [
                                        10,
                                        20,
                                        50,
                                        100,
                                        200,
                                        300
                                    ],
                                    setCurrentPage: this.$route.query.page
                                }"
                                :select-options="{
                                    enabled: true,
                                    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                                    selectionInfoClass: 'custom-class',
                                    selectionText: 'rows selected',
                                    clearSelectionText: 'clear',
                                    disableSelectInfo: true // disable the select info panel on top
                                }"
                                :group-options="{
                                    enabled: userSettings.byDate,
                                    collapsable: false
                                }"
                                row-style-class="mb-1 cursor-pointer"
                                :columns="columns"
                                :rows="
                                    userSettings.byDate ? rowsByDate : orders
                                "
                            >
                                <template slot="emptystate">
                                    <div
                                        class="flex h-40 w-full items-center justify-center bg-white"
                                    >
                                        <h3
                                            v-if="
                                                !isLoading && (ordersLength === 0 || currentPageOrdersCount === 0)
                                            "
                                            class="text-sm font-medium text-gray-700"
                                            >No orders found for the selected
                                            options</h3
                                        >
                                        <span v-else class="spinner"></span>
                                    </div>
                                </template>
                                <template slot="table-row" slot-scope="props">
                                    <span
                                        v-if="
                                            props.column.field ===
                                            'estimatedTime'
                                        "
                                    >
                                        <div v-if="props.row.estimatedTime"
                                             v-tooltip="props.row.preOrderDateTime ? `Pre-order, placed at: ${$moment(props.row.created_at).format('HH:mm - Do MMM, YY')}`: ''"
                                             class="flex items-center">
                                              <base-clock-icon class="h-4 w-4 text-gray-800 mr-2"  v-if="
                                                props.row.preOrderDateTime
                                                "/>
                                            <time
                                                class="block"
                                                :datetime="
                                                    props.row.estimatedTime
                                                "
                                                v-if="props.row.estimatedTime"
                                            >
                                                {{
                                                props.row.estimatedTime | moment('calendar', null, { sameDay: '[Today] [at] HH:mm',
                                                  nextDay: '[Tomorrow] [at] HH:mm',
                                                  nextWeek: 'dddd [at] HH:mm',
                                                  lastDay: '[Yesterday]  [at] HH:mm',
                                                  lastWeek: '[Last] dddd [at] HH:mm',
                                                  sameElse: 'Do MMM [at] HH:mm' })
                                                }}
                                            </time>
                                        </div>

                                        <div v-else>
                                            <time
                                                class="block"
                                                :datetime="props.row.created_at"
                                            >
                                                {{
                                                    props.row.created_at
                                                        | moment('from', 'now')
                                                }}
                                            </time>
                                            <span class="block">
                                                {{
                                                    props.formattedRow[
                                                        props.column.field
                                                    ]
                                                }}
                                            </span>
                                        </div>
                                    </span>
                                    <span
                                        v-else-if="
                                            props.column.field ===
                                            'fulfillmentMethod'
                                        "
                                    >
                                        <span class="flex items-center">
                                            <DeliveryIcon
                                                v-if="
                                                FulfillmentMethodsEnum.isDeliveryMethod(props.row
                                                        .fulfillmentMethod)
                                                "
                                                v-tooltip="'Delivery Order'"
                                                color="text-gray-500"
                                                size="sm"
                                            />
                                            <PickupIcon
                                                v-else-if="
                                                FulfillmentMethodsEnum.isPickupMethod(props.row
                                                        .fulfillmentMethod)
                                                "
                                                v-tooltip="'Pickup Order'"
                                                color="text-gray-500"
                                                size="sm"
                                            />
                                            <InstoreIcon
                                                v-else
                                                color="text-gray-500"
                                                size="sm"
                                            />
                                            <span
                                                v-if="
                                                    props.row.table &&
                                                    props.row.table.name
                                                "
                                                class="text-center mt-1 ml-2 text-gray-500"
                                                >{{
                                                    props.row.table.name
                                                }}</span
                                            >
                                          <span
                                              v-if="FulfillmentMethodsEnum.isCateringMethod(props.row.fulfillmentMethod)"
                                              class="font-semibold text-gray-600 pl-1 uppercase text-xs"
                                          >
                                            Catering
                                          </span>
                                        </span>
                                    </span>
                                    <span
                                        v-else-if="
                                            props.column.field ===
                                            'customer.firstName'
                                        "
                                        @click.stop
                                    >
                                        <CustomerDetailsTableItem
                                            v-if="props.row.customer"
                                            :email="props.row.customer.email"
                                            :phone="props.row.customer.phone"
                                            :name="`${props.row.customer.firstName} ${props.row.customer.lastName || ''}`"
                                            :show-email="false"
                                            :show-phone="false"
                                        />
                                    </span>
                                    <span
                                        v-else-if="
                                            props.column.field === 'payment'
                                        "
                                        @click.stop
                                    >
                                        <payment-details-popover
                                            :payment-type="
                                                props.row.paymentType
                                            "
                                            :refunds="props.row.refunds"
                                            :total-paid="
                                                props.row.total
                                            "
                                        />
                                    </span>
                                    <span
                                        v-else-if="
                                            props.column.field === 'total'
                                        "
                                        class="flex items-center"
                                        v-tooltip="props.row.paymentType !== 'Cash' ? 'Order paid online' : 'Order not paid online, or no balance due.'"
                                        @click.stop
                                    >
                                        <payment-details-popover
                                            :payment-type="
                                                props.row.paymentType
                                            "
                                            :refunds="props.row.refunds"
                                            :total-paid="
                                                  props.row.total"
                                        />
                                    </span>
                                    <span
                                        v-else-if="
                                            props.column.field === 'items'
                                        "
                                        @click.stop
                                    >
                                        <v-dropdown
                                            distance="16"
                                            :triggers="['click']"
                                            popperClass="order-item-popover"
                                        >
                                            <div
                                                class="group flex select-none items-center rounded-md px-4 py-1 hover:bg-gray-100"
                                            >
                                                <span class="mr-2 block">
                                                  <span class="p-2 w-2 h-2 bg-gray-100 text-gray-600 font-mono font-semibold inline-flex items-center justify-center border border-gray-300 mr-1 text-xs rounded-md">
                                                        {{
                                                            itemCount(
                                                                props.row.items
                                                            )
                                                        }}
                                                    </span>

                                                    {{
                                                        itemCount(
                                                            props.row.items
                                                        ) > 1
                                                            ? 'items'
                                                            : 'item'
                                                    }}
                                                </span>
                                                <svg
                                                    class="m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    focusable="false"
                                                    aria-hidden="true"
                                                >
                                                    <path
                                                        d="M5 8l5 5 5-5H5z"
                                                    ></path>
                                                </svg>
                                            </div>

                                            <template
                                                v-if="props.row.customer"
                                                #popper
                                            >
                                                <OrderItemsTooltip
                                                    :order-notes="
                                                        props.row?.customer
                                                            .notes
                                                    "
                                                    :order-items="
                                                        props.row.items
                                                    "
                                                />
                                            </template>
                                        </v-dropdown>
                                    </span>
                                    <status-badge
                                        v-else-if="
                                            props.column.field === 'orderStatus'
                                        "
                                        :status="props.formattedRow[props.column.field]"
                                    />
                                    <span v-else-if="props.column.field === 'venue.name'">
                                        {{
                                        props.row.venue.adminName || props.row.venue.name
                                        }}
                                    </span>
                                    <span
                                        v-else-if="
                                            props.column.field === 'id' &&
                                            props.row.code
                                        "
                                    >
                                        <span class="block font-mono font-bold">
                                            #{{ props.row.code }}
                                        </span>
                                    </span>
                                    <span v-else>
                                        {{
                                            props.formattedRow[
                                                props.column.field
                                            ]
                                        }}
                                    </span>
                                </template>
                                <template
                                    v-if="userSettings.byDate"
                                    slot="table-header-row"
                                    slot-scope="props"
                                >
                                    <div class="flex justify-start py-2">
                                        <span class="mr-2">
                                            <b>{{ props.row.label }}</b>
                                        </span>
                                        <span class="mr-2">
                                            <b
                                                >{{
                                                    formatCurrency(
                                                        props.row.sum
                                                    )
                                                }}
                                            </b>
                                        </span>
                                        <span class="mr-2">
                                            Orders:
                                            <b>{{ props.row.count }}</b>
                                        </span>
                                    </div>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
            <order-export-modal @exportOrders="exportOrders" />
        </main>
        <all-orders-filter-sidebar
            v-model="isFilterSidebarOpen"
            :userSettings="userSettings"
            :venues="venues"
            @setVenue="onVenueChange"
            @orderFilterChange="onOrderFilterChange"
            @dateRangeChange="onDateRangeChange"
            @toggleArchived="toggleArchived"
            @toggleRejected="toggleRejected"
            @clearFilters="clearFilters"
        />
    </div>
</template>

<script>
import qs from 'qs';
import ably from '@/helpers/ably';
import vClickOutside from 'v-click-outside';
import { Howl } from 'howler';
import { mapGetters, mapActions } from 'vuex';
import OrderItemsTooltip from '../components/widgets/OrderItemsTooltip';
import DeliveryIcon from '../components/common/DeliveryIcon';
import PickupIcon from '../components/common/PickupIcon';
import InstoreIcon from '../components/common/InstoreIcon';
import CustomerDetailsTableItem from '../components/widgets/CustomerDetailsTableItem';
import SearchInput from '@/components/widgets/SearchInput';
import { FeaturesEnum, FulfillmentMethodsEnum } from '@/enums';
import ExportButton from '@/components/helpers/ExportButton';
import StatusBadge from '@/components/StatusBadge';
import PaymentDetailsPopover from '@/components/widgets/PaymentDetailsPopover';
import AllOrdersFilterSidebar from '@/components/sidebars/AllOrdersFilterSidebar';
import OrderExportModal from '@/components/modals/OrderExportModal';

export default {
    name: 'AllOrders',
    metaInfo: {
        title: 'All Orders'
    },
    components: {
        StatusBadge,
        InstoreIcon,
        PickupIcon,
        DeliveryIcon,
        OrderItemsTooltip,
        CustomerDetailsTableItem,
        SearchInput,
        ExportButton,
        PaymentDetailsPopover,
        AllOrdersFilterSidebar,
        OrderExportModal
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            FeaturesEnum,
            FulfillmentMethodsEnum,
            orders: [],
            ordersLength: null,
            currentPageOrdersCount: null,
            hasNewOrders: 0,
            isLoading: false,
            currentPage: 1,
            totalPages: null,
            showVenueFilter: false,
            showOrderFilter: false,
            serverParams: {
                page: 1,
                limit: 20,
                venueId: null,
                fulfillmentMethod: null,
                showRejected: false,
                byDate: false,
                archived: false,
                tableId: null,
                search: null
            },
            columns: [
                {
                    label: 'Order',
                    field: 'id',
                    type: 'text',
                    sortable: false,
                    formatFn: this.formatOrderNumber,
                    tdClass:
                        'tailwind-td py-1 text-gray-600 hover:underline font-medium',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Type',
                    field: 'fulfillmentMethod',
                    type: 'text',
                    sortable: false,
                    tdClass:
                        'table-cell px-6 py-1 whitespace-nowrap text-sm leading-5 text-gray-700',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Requested for',
                    field: 'estimatedTime',
                    sortable: false,
                    firstSortType: 'desc',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
                    dateOutputFormat: 'HH:mm - d MMM, yy',
                    tdClass:
                        'table-cell px-6 py-1 whitespace-nowrap text-sm leading-5 text-gray-700',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Total',
                    field: 'total',
                    type: 'text',
                    sortable: false,
                    firstSortType: 'desc',
                    formatFn: this.formatCurrency,
                    tdClass:
                        'px-2 py-1 whitespace-nowrap text-right text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Status',
                    field: 'orderStatus',
                    type: 'text',
                    sortable: false,
                    firstSortType: 'desc',
                    tdClass:
                        'px-6 py-1 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 text-right bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Items',
                    field: 'items',
                    type: 'text',
                    sortable: false,
                    tdClass:
                        'items-table-col px-2 py-1 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-10 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Customer',
                    field: 'customer.firstName',
                    type: 'text',
                    sortable: false,
                    tdClass:
                        'customer-table-col px-2 py-1 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Store',
                    field: 'venue.name',
                    type: 'text',
                    sortable: false,
                    tdClass:
                        'px-6 py-1 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                }
            ],
            selectedRows: [],
            channels: [],
            isFilterSidebarOpen: false,
            dateFilters: [
                { label: 'All', key: 'all' },
                { label: 'Today', key: 'today' },
                {
                    label: 'Tomorrow',
                    key: 'tomorrow'
                },
                { label: 'This week', key: 'week' }
            ],
            selectedFilter: 'all'
        };
    },
    async mounted() {
        if (!this.venues?.length > 0) {
            this.fetchVenues();
        }

        this.serverParams = {
            ...this.serverParams,
            showRejected: this.userSettings.showRejected,
            limit: parseInt(this.userSettings.limit) || 20,
            byDate: this.userSettings.byDate,
            fulfillmentMethod: this.userSettings.orderFilter,
            venueId: this.userSettings.venueFilter,
            archived: this.userSettings.archived,
            from: this.userSettings.from,
            to: this.userSettings.to,
            tableId: null,
            search: null
        };

        if (this.$route.query && Object.keys(this.$route.query).length > 0) {
            const parsedParameters = this.parseQueryString({
                ...this.$route.query
            });

            this.updateParams(parsedParameters);
            this.updateUserSettings(parsedParameters);
        } else {
            this.fetchOrders();
        }

        this.userSettings && this.userSettings.venueFilter
            ? this.listenOrders(this.userSettings.venueFilter)
            : this.listenOrders();
    },
    beforeDestroy() {
        this.unsubscribeAll();
    },
    methods: {
        ...mapActions({
            editUserSettings: 'allOrders/editUserSettings',
            fetchVenues: 'venues/fetch'
        }),
        parseQueryString({
            venueId,
            archived,
            fulfillmentMethod,
            byDate,
            limit,
            showRejected,
            tableId,
            search
        }) {
            return {
                venueId: Array.isArray(venueId)
                    ? venueId.filter(Boolean)
                    : parseInt(venueId) || null,
                archived: archived ? archived.toLowerCase() === 'true' : false,
                fulfillmentMethod: fulfillmentMethod || null,
                byDate: byDate ? byDate.toLowerCase() === 'true' : false,
                limit: parseInt(limit) || 20,
                showRejected: showRejected
                    ? showRejected.toLowerCase() === 'true'
                    : false,
                tableId: tableId || null,
                search: search || null
            };
        },
        updateUserSettings({
            venueId,
            archived,
            fulfillmentMethod,
            byDate,
            limit,
            showRejected
        }) {
            this.userSettings.venueFilter = venueId || null;
            this.userSettings.archived = archived || false;
            this.userSettings.orderFilter = fulfillmentMethod;
            this.userSettings.byDate = byDate || false;
            this.userSettings.limit = limit || 20;
            this.userSettings.showRejected = showRejected || false;
        },
        isToday(val) {
            const date = new Date(val);
            const today = new Date();
            return (
                date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
            );
        },
        onClickOutside() {
            this.showOrderFilter = false;
        },
        fetchOrders() {
            this.isLoading = true;
            const params = this.serverParams;

            this.$axios
                .get('/orders', {
                    params,
                    paramsSerializer: {
                        serialize: params => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                                skipNulls: true
                            });
                        }
                    }
                })
                .then(res => {
                    this.orders = res.data.results;
                    this.ordersLength = res.data.meta.totalCount;
                    this.currentPageOrdersCount = res.data.meta.count;
                    this.totalPages = res.data.meta.pageCount;
                    this.$modal.hide('api-error-modal');
                })
                .catch(e => {
                    this.$modal.show('api-error-modal');
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.hasNewOrders = 0;
                });
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.fetchOrders();
        },
        onPageChange({ currentPage }) {
            this.updateParams({
                page: currentPage
            });
        },
        onPerPageChange({ currentPerPage }) {
            this.updateParams({
                limit: currentPerPage
            });

            this.userSettings.limit = currentPerPage;
        },
        onDateRangeChange({ startDate, endDate, selectedFilter = 'all' }) {
            this.updateParams({
                from: startDate,
                to: endDate
            });

            this.userSettings.from = startDate;
            this.userSettings.to = endDate;
            this.selectedFilter = selectedFilter;
        },
        onVenueChange(venueIds) {
            this.updateParams({
                venueId: venueIds
            });

            this.userSettings.venueFilter = venueIds;
        },
        onOrderFilterChange(fulfillmentMethod) {
            this.updateParams({
                fulfillmentMethod
            });

            this.userSettings.orderFilter = fulfillmentMethod;
        },
        onSortChange(params) {
            this.updateParams({
                page: 1,
                sortOrder: params[0].type,
                sortBy: params[0].field
            });
        },
        filteredByMethod() {
            if (this.userSettings.orderFilter === null) {
                return this.orders;
            } else if (this.userSettings.orderFilter === 'Pickup') {
                const orders = this.orders.filter(
                    order => order.fulfillmentMethod === 'Pickup'
                );
                return orders;
            } else if (this.userSettings.orderFilter === 'InStore') {
                return this.orders.filter(
                    order => order.fulfillmentMethod === 'InStore'
                );
            } else {
                const orders = this.orders.filter(
                    order => order.fulfillmentMethod === 'Delivery'
                );
                return orders;
            }
        },
        toggleRejected() {
            if (!this.isLoading) {
                this.isLoading = true;
                this.userSettings.showRejected = !this.userSettings
                    .showRejected;
                this.updateParams({
                    showRejected: this.userSettings.showRejected
                });
            }
        },
        toggleArchived() {
            if (!this.isLoading) {
                this.isLoading = true;
                this.userSettings.archived = !this.userSettings.archived;
                this.updateParams({
                    archived: this.userSettings.archived
                });
            }
        },
        toggleGroupByDate() {
            if (!this.isLoading) {
                this.isLoading = true;
                this.userSettings.byDate = !this.userSettings.byDate;
                this.updateParams({
                    byDate: this.userSettings.byDate
                });
            }
        },
        searchById(searchString) {
            if (!this.isLoading) {
                this.isLoading = true;
                this.userSettings.search = searchString;
                this.updateParams({
                    search: this.userSettings.search,
                    page: 1
                });
            }
        },
        formatCurrency: function (value) {
            return this.$options.filters.currency(parseInt(value) / 100);
        },
        formatOrderNumber: function (value) {
            return '#' + value;
        },
        isCheckboxEvent(event) {
            if (!event.composedPath() || !event.composedPath().length) {
                return false;
            }

            for (const path of event.composedPath()) {
                const dom = path;

                if (
                    (dom &&
                        dom.classList &&
                        dom.classList.contains('vgt-checkbox-col')) ||
                    dom?.classList?.contains('items-table-col') ||
                    dom?.classList?.contains('customer-table-col')
                )
                    return true;
            }

            return false;
        },
        navigateToOrder({ event, row }) {
            if (!this.isCheckboxEvent(event)) {
                if (event.metaKey || event.ctrlKey) {
                    const routeData = this.$router.resolve({
                        name: 'orderDetail',
                        params: {
                            id: row.id
                        }
                    });

                    window.open(routeData.href, '_blank');
                } else {
                    this.$router.push({
                        name: 'orderDetail',
                        params: {
                            id: row.id
                        }
                    });
                }
            }
        },
        itemCount(order) {
            if (order) {
                return order.reduce((total, item) => {
                    return total + item.quantity;
                }, 0);
            } else {
                return 0;
            }
        },
        changeSelectedRows({ selectedRows }) {
            this.selectedRows = selectedRows;
        },
        async acceptOrders() {
            const ACCEPTED_STATUS = 'Accepted';
            const REJECTED_STATUS = 'Rejected';
            const PENDING_STATUS = 'Pending';

            const rejectedOrder = this.selectedRows.find(
                selectedRow => selectedRow.orderStatus === REJECTED_STATUS
            );

            if (rejectedOrder) {
                this.$modal.show('custom-error-modal', {
                    title: "Sorry, that didn't work",
                    text: "You can't approve rejected orders"
                });

                return;
            }

            const pendingOrders = this.selectedRows.filter(
                selectedRow => selectedRow.orderStatus === PENDING_STATUS
            );

            if (!pendingOrders.length) {
                return;
            }

            try {
                this.isLoading = true;

                await this.$axios.put('/orders/bulk-accept', pendingOrders);

                this.selectedRows.map(selectedRow => {
                    if (selectedRow.orderStatus === PENDING_STATUS) {
                        selectedRow.orderStatus = ACCEPTED_STATUS;
                    }
                });
                this.$notify({
                    group: 'settings',
                    title: `Accepted ${pendingOrders.length} orders`
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async bulkPrintOrders() {
            const ordersToPrint = this.selectedRows.map(({ id }) => ({
                id
            }));

            if (!ordersToPrint.length) {
                return;
            }

            try {
                this.isLoading = true;

                await this.$axios.put('/orders/bulk-print', ordersToPrint);

                this.$notify({
                    group: 'settings',
                    title: `Queued ${ordersToPrint.length} orders for printing`
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        listenOrders(venueIds) {
            if (venueIds && venueIds.length) {
                for (const venueId of venueIds) {
                    this.subscribeById(venueId);
                }

                return;
            }

            for (const venue of this.venues) {
                this.subscribeById(venue.id);
            }
        },
        subscribeById(venueId) {
            if (!venueId) {
                return;
            }

            const channel = ably.channels.get(`all-orders-${venueId}`);

            channel.subscribe(`all-orders-event-${venueId}`, () => {
                this.hasNewOrders++;
                this.playSound();
            });

            this.channels[`all-orders-${venueId}`] = channel;
        },
        unsubscribeById(venueId) {
            if (!venueId) {
                return;
            }

            if (this.channels[`all-orders-${venueId}`]) {
                this.channels[`all-orders-${venueId}`].detach();
            }
        },
        unsubscribeAll() {
            for (const venue of this.venues) {
                this.unsubscribeById(venue.id);
            }
        },
        subscribeAll() {
            for (const venue of this.venues) {
                this.subscribeById(venue.id);
            }
        },
        playSound() {
            const maxIterations = 3;
            let counter = 0;

            const sound = new Howl({
                src: [
                    'https://ucarecdn.com/5486f7b7-ebe9-42d3-bea2-852703d95158/newOrder11.mp3'
                ],
                loop: true,
                onloaderror: () => {
                    this.$notify({
                        group: 'settings',
                        title: 'Sound cannot be played'
                    });
                },
                onplayerror: () => {
                    sound.once('unlock', () => {
                        sound.play();
                    });
                },
                onend: () => {
                    counter++;

                    if (counter === maxIterations) {
                        sound.loop(false);
                    }
                }
            });

            sound.play();
        },
        async exportOrders(isExpanded) {
            this.isLoading = true;
            const params = {
                ...this.serverParams,
                // limit: this.ordersLength,
                // @TODO limit is broken with row estimator api function
                limit: 5000,
                byDate: false,
                isExpanded
            };
            // const params = this.serverParams;
            // params.limit = this.ordersLength;
            // params.isExpanded = isExpanded;
            // Unsupported for csv export
            // params.byDate = false;
            try {
                await this.$axios.get('/orders/export?', {
                    params,
                    paramsSerializer: {
                        serialize: params => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                                skipNulls: true
                            });
                        }
                    }
                });

                this.$notify({
                    group: 'settings',
                    title: 'Export requested'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        openFilterSidebar() {
            this.isFilterSidebarOpen = true;
        },
        clearFilters() {
            this.userSettings.showRejected = false;
            this.userSettings.archived = false;
            this.userSettings.orderFilter = null;
            this.userSettings.venueFilter = null;
            this.userSettings.from = null;
            this.userSettings.to = null;

            this.updateParams({
                venueId: null,
                archived: false,
                showRejected: false,
                fulfillmentMethod: null,
                to: null,
                from: null
            });
        },
        showOrderExportModal() {
            this.$modal.show('order-export-modal');
        },
        applyDateFilter(selectedFilter) {
            let startDate = null;
            let endDate = null;

            const today = new Date();

            if (selectedFilter === 'today') {
                startDate = new Date(today.setHours(0, 0, 0, 0));
                endDate = new Date(today.setHours(23, 59, 59, 0));
            } else if (selectedFilter === 'tomorrow') {
                const tomorrow = new Date(today);
                tomorrow.setDate(tomorrow.getDate() + 1);

                startDate = new Date(tomorrow.setHours(0, 0, 0, 0));
                endDate = new Date(tomorrow.setHours(23, 59, 59, 0));
            } else if (selectedFilter === 'week') {
                startDate = this.$moment(today).startOf('day').format();
                endDate = this.$moment(today).day(7).endOf('day').format();
            }

            this.onDateRangeChange({
                startDate,
                endDate,
                selectedFilter
            });
        }
    },
    computed: {
        ...mapGetters({
            userSettings: 'allOrders/userSettings',
            isFeatureAvailable: 'user/isFeatureAvailable',
            venues: 'venues/getVenues'
        }),
        preSelectedVenues() {
            const queriedVenueId = this.$route.query.venueId;

            return Array.isArray(queriedVenueId)
                ? queriedVenueId
                : parseInt(queriedVenueId) ||
                      this.userSettings.venueFilter ||
                      [];
        },
        selectedPendingRows() {
            return this.selectedRows.filter(
                ({ orderStatus }) => orderStatus === 'Pending'
            );
        },
        rowsByDate() {
            if (this.orders[0] && this.orders[0].id) {
                return [];
            }

            return this.orders.map(object => {
                return {
                    mode: 'span',
                    label: object.day,
                    html: false,
                    sum: object.sum,
                    count: object.count,
                    children: object.orders,
                    thClass: 'table-header-date',
                    tdClass: 'table-header-date'
                };
            });
        },
        selectedFilters() {
            let countSelected = 0;

            if (this.userSettings.venueFilter) {
                countSelected++;
            }

            if (this.userSettings.orderFilter) {
                countSelected++;
            }

            if (this.userSettings.archived) {
                countSelected++;
            }

            if (this.userSettings.showRejected) {
                countSelected++;
            }

            if (this.userSettings.from || this.userSettings.to) {
                countSelected++;
            }

            return countSelected;
        }
    },
    watch: {
        currentPage: function () {
            this.fetchOrders();
        },
        userSettings: {
            deep: true,
            handler(settings) {
                this.editUserSettings(settings);
            }
        },
        serverParams(newValue) {
            const parameters = qs.stringify(newValue, {
                arrayFormat: 'repeat',
                skipNulls: true,
                addQueryPrefix: true
            });

            history.pushState({}, '', this.$route.path + parameters);
        },
        'userSettings.venueFilter': function (newValue, oldValue) {
            if (!newValue && oldValue) {
                newValue = [];

                for (const venue of this.venues) {
                    if (venue.id) {
                        newValue.push(venue.id);
                    }
                }
            }

            if (!oldValue || !oldValue.length) {
                oldValue = [];
                this.unsubscribeAll();
            }

            if (oldValue.length && newValue && newValue.length) {
                const venueIds = oldValue.filter(n => !newValue.includes(n));

                if (venueIds) {
                    for (const venueId of venueIds) {
                        this.unsubscribeById(venueId);
                    }
                }
            }

            if (!newValue || !newValue.length) {
                newValue = [];

                if (oldValue && oldValue.length) {
                    this.subscribeAll();
                }
            }

            if (newValue.length) {
                const venueIds = newValue.filter(n => !oldValue.includes(n));

                if (venueIds) {
                    for (const venueId of venueIds) {
                        this.subscribeById(venueId);
                    }
                }
            }
        }
    }
};
</script>

<style>
tbody tr th.vgt-row-header :not(.vgt-checkbox-col) {
    text-transform: none;
    border-bottom: none;
    cursor: default;
    user-select: none;
}
</style>
