<template>
    <div>
        <base-announcement-banner
            v-if="!venue.enabled && isFeatureAvailable(FeaturesEnum.INTERNAL)"
            text="Your store is not accepting orders"
        >
            <toggle-switch @input="toggleEnabled" :value="venue.enabled" />
        </base-announcement-banner>

<!--        <base-announcement-banner-->
<!--            v-if="!venue.isLive && isFeatureAvailable(FeaturesEnum.INTERNAL)"-->
<!--            text="Store is in test mode"-->
<!--        >-->
<!--            <base-button @clicked="goLive" buttonText="Go Live!" />-->
<!--        </base-announcement-banner>-->
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
              <router-link
                  :to="{ name: 'stores' }"
                  class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
              >
                <base-back-icon />
                <span>Stores</span>
              </router-link>
                <base-page-title title="Store Settings" />
            </div>
            <BaseSpinner :isLoading="isLoading" />
        </div>

        <div class="mt-6 overflow-hidden rounded-lg bg-white shadow">
            <div class="grid grid-cols-6">
                <settings-list-item
                    :key="index"
                    v-for="(setting, index) in settings"
                    :title="setting.title"
                    :route-name="setting.routeName"
                    :description="setting.description"
                    v-show="isSettingVisible(setting)"
                ></settings-list-item>
            </div>
        </div>

        <div class="mt-6">
            <base-page-title title="Integrations" />
        </div>

        <div class="mt-6 overflow-hidden rounded-lg bg-white shadow">

          <div class="grid grid-cols-6">
            <ul class="flex flex-row items-center mb-2 sm:mb-0 px-2 sm:px-6 py-2.5 border-b w-full col-span-6 overflow-scroll">
              <li
                  class="mr-1 cursor-pointer font-medium rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50"
                  :class="{
                      'bg-gray-100':
                          filter === integrationFilter
                  }"
                  v-for="filter in integrationFilters"
                  :key="filter"
                  @click="integrationFilter = filter"
              >
                {{ filter }}
              </li>
            </ul>
            <integration-list-item
                :key="integration.value"
                v-for="integration in filteredIntegrations"
                :integration="integration"
                :userParagonIntegrations="userParagonIntegrations"
                :venue="venue"
                @click.native="integration.paragonId ? paragonIntegration(integration.paragonId): false;"
                v-show="isIntegrationVisible(integration)"
            />
            <div class="group col-span-6 rounded-sm cursor-pointer">
              <router-link
                  :to="{ name: 'feedback' }"
                  class="hover:bg-gray-50 py-4 border-b transition-all duration-300 ease-in-out flex justify-between items-center px-5 ">
                <h4
                    class="text-sm text-gray-700 font-semibold flex items-center group-hover:text-indigo-700"
                >
                  Request a new integration
                  <base-conversation-icon class="ml-1 h-4 w-4 "/>
                </h4
                >
              </router-link>
            </div>
          </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import SettingsListItem from '@/components/venue/settings/SettingsListItem';
import IntegrationListItem from '@/components/venue/settings/IntegrationListItem';
import BaseAnnouncementBanner from '../../base/BaseAnnouncementBanner';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import BaseConversationIcon from "@/components/base/icons/BaseConversationIcon.vue";
export default {
    name: 'settingsList',
    components: {
      BaseConversationIcon,
        BaseAnnouncementBanner,
        SettingsListItem,
        IntegrationListItem,
        ToggleSwitch
    },
    metaInfo: {
        title: 'Store Settings'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            accountId: 'user/accountId'
        }),
        isOrderAndPay() {
            return this.venue.acceptsInStore;
        },
        filteredIntegrations() {
          if (!this.integrationFilter || this.integrationFilter === 'All') {
            return this.integrations;
          }
          return this.integrations.filter(integration =>
              integration.tags.includes(this.integrationFilter)
          );
        }
    },
    mounted() {
        const script = document.getElementById(this.paragonScriptId);

        if (!script) {
            this.insertParagonScript();
            this.paragonUser();
        } else {
          this.paragonUser();
        }

        if (this.isFeatureAvailable(FeaturesEnum.IKENTOO)) {
          this.integrations.unshift({
            title: 'Lightspeed K-Series',
            value: 'lightspeed',
            routeName: 'lightspeedSettings',
            description: 'Manage your Lightspeed K-Series integration settings',
            logo: '',
            icon: 'https://ucarecdn.com/b0b88b84-b70f-4bca-8702-f4dc02dd72bf/-/format/auto/-/resize/200x/',
            tags: ['POS'],
            isSaas: false,
            internal: false
          });
        }
        if (this.isFeatureAvailable(FeaturesEnum.COMTREX)) {
          this.integrations.unshift({
            title: 'Comtrex',
            value: 'comtrex',
            routeName: 'comtrexSettings',
            description: 'Manage your Zonal Comtrex integration settings',
            logo: '',
            icon: 'https://ucarecdn.com/51145a23-3034-4a4b-8a55-d5f05c3fde9b/-/format/auto/-/resize/200x/',
            tags: ['POS'],
            isSaas: false,
            internal: false
          });
        }
        if (this.isFeatureAvailable(FeaturesEnum.CENTEGRA)) {
          this.integrations.unshift({
            title: 'Centegra',
            value: 'centegra',
            routeName: 'centegraSettings',
            description: 'Connect to sync orders into Centegra EPOS.',
            logo: '',
            icon: 'https://ucarecdn.com/e956c02a-438a-4e0d-9691-8690ef16007a/-/format/auto/-/resize/200x/',
            tags: ['POS'],
            isSaas: false,
            internal: false
          });
        }
        if (this.isFeatureAvailable(FeaturesEnum.TISSL)) {
          this.integrations.unshift({
            title: 'Tissl',
            value: 'tissl',
            routeName: 'tisslSettings',
            description: 'Connect to sync orders into Tissl EPOS',
            logo: '',
            icon: 'https://ucarecdn.com/72a08221-873b-46a5-9d58-ce417be32057/-/format/auto/-/resize/200x/',
            tags: ['POS'],
            isSaas: true,
            internal: false
          });
          if (this.isFeatureAvailable(FeaturesEnum.ZONAL)) {
            this.integrations.unshift({
              title: 'Zonal',
              value: 'zonal',
              routeName: 'zonalSettings',
              description: 'Connect to sync orders into Zonal EPOS',
              logo: '',
              icon: 'https://ucarecdn.com/a68c7924-1c87-4919-a81e-2da3d22467d9/-/format/auto/-/resize/200x/',
              tags: ['POS'],
              isSaas: true,
              internal: false
            });
          }

          if (!this.accountId === 'f2b595dd-35b5-44ef-8c10-16a534dfab84') {
            this.integrations.shift(
                {
                  title: 'Nash',
                  value: 'nash',
                  routeName: 'nashSettings',
                  description: 'Organise on-demand and scheduled delivery orders with Nash\'s delivery management software',
                  logo: '',
                  icon: 'https://ucarecdn.com/3595a6df-0d5c-4dbe-839f-ece596dfbb83/-/format/auto/-/resize/200x/',
                  tags: ['Delivery'],
                  isSaas: true,
                  visibility: 'takeaway'
                }
            )
          }

        }
    },
    beforeDestroy() {
        const script = document.getElementById(this.paragonScriptId);

        if (script) {
            script.remove();
        }
    },
  data() {
        return {
            isLoading: false,
            FeaturesEnum,
            paragonScriptId: 'paragon-js',
            userParagonIntegrations: null,
            settings: [
                {
                    title: 'General',
                    routeName: 'generalSettings',
                    description:
                        'View and update your store details; contact info, physical address and marketing optin'
                },
                {
                    title: 'Branding',
                    routeName: 'brandingSettings',
                    description:
                        'Customise your store with branded assets and colour schemes'
                },
                {
                    title: 'Opening Hours',
                    routeName: 'openingHoursSettings',
                    description: 'Control when your customers can place orders'
                },
                {
                    title: 'Operations',
                    routeName: 'opeationsSettings',
                    description:
                        'Manage how you receive, accept and process orders'
                },
                {
                    title: 'Delivery Zones',
                    routeName: 'deliveryZonesSettings',
                    description:
                        'Create and manage where you deliver to',
                    visibility: 'takeaway'
                },
                {
                    title: 'Store Notices',
                    routeName: 'storeNoticeSettings',
                    description:
                        'Display custom messages to customers throughout the purchase journey'
                },
                {
                    title: 'Pre-Orders',
                    routeName: 'pickupSlotSettings',
                    description: 'Enable and manage pre-orders slot settings'
                },
                {
                    title: 'Direct Printing',
                    routeName: 'printerIntegration',
                    description:
                        'Add cloud connected printers to print storekit orders without a POS system'
                },
                {
                    title: 'Tables',
                    routeName: 'OrderAndPaySettings',
                    description:
                        'Manage your in-store revenue area and table plans',
                    visibility: 'opat'
                },
                {
                    title: 'Payments',
                    routeName: 'paymentSettings',
                    description:
                        'Enable and manage your storekit payments account'
                },
                {
                    title: 'Gratuity',
                    routeName: 'tipSettings',
                    description:
                        'Maximise your gratuity takings with optional and mandatory tips and service charges'
                },
                {
                    title: 'Feedback',
                    routeName: 'feedbackSettings',
                    description: 'Collect feedback from customers on each order'
                }
                // {
                //   title: 'Loyalty',
                //   routeName: 'piggySettings',
                //   description: 'Configure how loyalty points are earned and redeemed'
                // }
            ],
            integrations: [
             {
               title: 'Meta Pixel',
               value: 'meta',
               routeName: 'facebookSettings',
               description: 'Connect your Meta Ads account to measure advertising performance on Facebook and Instagram',
               logo: '',
               icon: 'https://ucarecdn.com/56c94c87-5a3b-497d-8314-c5f83534d4d0/-/format/auto/-/resize/200x/',
               tags: ['Marketing']
             },
             {
               title: 'Google Tag Manager',
               value: 'google-tag-manager',
               routeName: 'googleAnalyticsSettings',
               description: 'Connect to include your google tag manager tracking script on your store and send conversion data to Google',
               logo: '',
               icon: 'https://ucarecdn.com/a653ba7f-3e25-49ca-b5fe-696eac59cbc4/-/format/auto/-/resize/200x/',
               tags: ['Marketing'],
               internal: true
             },
             {
               title: 'Toggle',
               value: 'toggle',
               routeName: 'toggleSettings',
               description: 'Connect to Toggle and allow guests to redeem gift cards at checkout',
               logo: '',
               icon: 'https://assets-global.website-files.com/60fa8559f5a36b6e968b6ed3/616984c69196371835610bda_webclip.png',
               tags: ['Marketing'],
               isSaas: true,
               internal: true
             },
              {
                title: 'TipJar',
                value: 'tipjar',
                routeName: 'tipjarSettings',
                description: 'Enable TiPJAR to allow customers to easily tip your team directly',
                icon: 'https://ucarecdn.com/eb0e6ba4-7482-44df-aef1-53fc5e27c193/-/format/auto/-/resize/200x/',
                tags: ['Tips']
              },
            {
              title: 'Piggy',
              value: 'piggy',
              routeName: 'piggySettings',
              description: 'Loyalty',
              logo: '',
              icon: 'https://ucarecdn.com/d38fd599-21f3-4fbc-bfa7-852aa546099d/-/format/auto/-/resize/200x/',
              tags: ['Marketing'],
              isSaas: true,
              internal: true
            },
             {
               title: 'Mailchimp',
                value: 'mailchimp',
               description: 'Connect to automatically create or update customer details in Mailchimp',
               logo: '',
               icon: 'https://ucarecdn.com/0ed917c4-98c5-442c-9123-39ee70e7d186/-/format/auto/-/resize/200x/',
               tags: ['Marketing'],
               isSaas: false,
               paragonId: 'mailchimp'
             },
             {
               title: 'OpenTable',
               value: 'opentable',
               routeName: 'opentableSettings',
               description: 'Connect to sync your menus to the OpenTable app and websites',
               logo: '',
               icon: 'https://ucarecdn.com/f4fcc8dc-8fd5-47aa-9390-7c0070b13f24/-/format/auto/-/resize/200x/',
               tags: ['Marketing'],
               internal: true
             },
              {
                title: 'Airship',
                value: 'airship',
                routeName: 'airshipSettings',
                description: ' Connect to Airship CRM to send contact and purchase data',
                icon: 'https://ucarecdn.com/1936dc46-486d-4f21-8968-9207bdb8bde2/-/format/auto/-/resize/200x/',
                tags: ['Marketing'],
                isSaas: true
              },
             {
               title: 'Klaviyo',
                value: 'klaviyo',
               routeName: 'klaviyoSettings',
               description: 'Connect to Klaviyo to auto import customer details and track purchase behaviour on your store',
               logo: '',
               icon: 'https://logosandtypes.com/wp-content/uploads/2022/04/Klaviyo.png',
               isSaas: true,
               tags: ['Marketing']
             },
              {
                title: 'Stampede',
                value: 'stampede',
                description: 'Connect to automatically send customer visit and purchase data into Stampede',
                logo: '',
                icon: 'https://ucarecdn.com/a95b05f1-b822-40e1-9690-637027bc62ce/-/format/auto/-/resize/200x/',
                isSaas: true,
                paragonId: 'custom.stampede',
                tags: ['Marketing', 'WiFi']
              },
             {
               title: 'Fresh KDS',
               value: 'fresh-kds',
               routeName: 'freshkdsSettings',
               description: 'Connect to FreshKDS to send new orders into the digital kitchen display',
               logo: '',
               icon: 'https://ucarecdn.com/5e021a8d-0065-4234-ae79-6687f84424e5/-/format/auto/-/resize/200x/',
               tags: ['Operations'],
               internal: true
             },
             {
               title: 'Deliverect',
                value: 'deliverect',
               routeName: 'deliverectSettings',
               description: 'Connect to Deliverect to sync menus and aggregate orders into deliverect',
               logo: '',
               icon: 'https://ucarecdn.com/560ce4df-7960-42ce-89da-16cc7b304572/-/format/auto/-/resize/200x/',
               tags: ['POS', 'Operations']
             },
            {
              title: 'Uber Direct',
              value: 'uber-direct',
              routeName: 'uberDirectSettings',
              description: 'Connect to Uber Direct to offer your own delivery service, powered by Uber\'s network of couriers',
              logo: '',
              icon: 'https://ucarecdn.com/17f8e456-2b0d-4979-9fb5-5579618eaa3e/-/format/auto/-/resize/200x/',
              tags: ['Delivery'],
              visibility: 'takeaway',
              isSaas: true,
              internal: true
            },
              {
                title: 'Orkestro',
                value: 'orkestro',
                routeName: 'orkestroSettings',
                description: 'Enable Orkestro to manage your delivery service and dispatch orders to couriers',
                logo: '',
                icon: 'https://ucarecdn.com/b5d9eb8e-aa83-4136-aacb-a8577bf886c9/-/format/auto/-/resize/200x/',
                tags: ['Delivery'],
                isSaas: true,
                visibility: 'takeaway'
              },
              {
                title: 'Shipday',
                value: 'shipday',
                routeName: 'shipdaySettings',
                description: 'Connect with Shipday to manage your own delivery drivers and route planning',
                logo: '',
                icon: 'https://ucarecdn.com/f787a41a-d881-482e-9522-7aa199c2fc84/-/format/auto/-/resize/200x/',
                tags: ['Delivery'],
                visibility: 'takeaway'
              },
             {
               title: 'Captive WiFi',
               value: 'captive-wifi',
               routeName: 'captiveWifiSettings',
               description: 'Connect to prefill customer checkout information when connecting through your guest WiFi',
               logo: '',
               icon: 'https://ucarecdn.com/9e29ffb0-d999-4e67-8946-a20334b5834e/-/format/auto/-/resize/200x/',
               tags: ['WiFi', 'Marketing'],
             },
              {
                title: 'Kitchen Cut',
                value: 'kitchen-cut',
                routeName: 'kitchenCutSettings',
                description: 'Send daily purchase data into kitchen cut to manage your inventory and F&B costing',
                logo: '',
                icon: 'https://ucarecdn.com/6120e977-6840-4de8-8636-46ca2cbc1396/-/format/auto/-/resize/200x/',
                tags: ['Inventory'],
                isSaas: true,
                internal: false
              },
              {
                title: 'Wolt Drive',
                value: 'wolt',
                routeName: 'woltSettings',
                description: 'Connect to Wolt Drive to offer your own delivery service, powered by Wolt\'s network of couriers',
                logo: '',
                icon: 'https://ucarecdn.com/89593d30-8d48-406f-a667-44fbf879f2c6/-/format/auto/-/resize/200x/',
                tags: ['Delivery'],
                visibility: 'takeaway',
                isSaas: true,
                internal: true
              },
             {
               title: 'Webhooks',
               value: 'webhooks',
               routeName: 'webhooks',
               description: 'Build custom integrations easily with our flexible webhook solution',
               logo: '',
               icon: 'https://ucarecdn.com/bacd3759-62e6-456c-953b-6f3c65e59c8e/-/format/auto/-/resize/200x/',
               tags: ['API'],
               isSaas: true
             },
              {
                title: 'Instagram',
                value: 'instagram',
                routeName: 'instagramSettings',
                description: 'Connect your Instagram account to display a feed of recent posts on the order status screen',
                icon: 'https://ucarecdn.com/bd09dca5-4c5e-43c3-ad23-df47a96da397/-/format/auto/-/resize/200x/',
                tags: ['Marketing'],
                internal: true
              },
              {
                title: 'Segment.com',
                value: 'segment',
                routeName: 'segmentSettings',
                description: 'Connect to segment',
                icon: 'https://ucarecdn.com/a6d611ba-4609-4a9e-95c8-1062f8e5f530/-/format/auto/-/resize/200x/',
                tags: ['Marketing'],
                isSaas: true,
                internal: true
              },
              {
                title: 'Acteol',
                value: 'atreemo',
                routeName: 'atreemoSettings',
                description: 'Connect to Acteol CRM',
                icon: 'https://ucarecdn.com/a4cf23f4-e008-46f9-a40c-3fbeed8e7e00/-/format/auto/-/resize/200x/',
                tags: ['Marketing'],
                isSaas: true,
                internal: false
              },
              {
                title: 'Giftpro',
                value: 'giftpro',
                routeName: 'giftproSettings',
                description: 'Connect to Giftpro',
                icon: 'https://ucarecdn.com/602f10d3-995b-41e0-9739-c99ad56beacc/-/format/auto/-/resize/200x/',
                tags: ['Marketing'],
                isSaas: true,
                internal: false
              },
              {
                title: 'Gophr',
                value: 'gophr',
                routeName: 'gophrSettings',
                description: 'Connect to Gophr\'s network of same day and on-demand couriers',
                icon: 'https://ucarecdn.com/c2a11fef-8a91-47cb-8023-19d6216aed19/-/format/auto/-/resize/200x/',
                tags: ['Delivery'],
                isSaas: true,
                internal: true
              },
              {
                title: 'Pedal Me',
                value: 'pedalme',
                routeName: 'pedalMeSettings',
                description: 'Connect to Pedal Me\'s network of electric cargo bike couriers in London',
                icon: 'https://ucarecdn.com/721c860f-411b-47f8-bd18-a6d3b06adffe/-/format/auto/-/resize/200x/',
                tags: ['Delivery'],
                isSaas: true,
                internal: true
              }
            ],
            integrationFilter: 'All',
            integrationFilters: ['All', 'Marketing','Delivery','Inventory', 'Operations', 'POS','WiFi', 'API', 'Tips']
        };
    },
    methods: {
        insertParagonScript() {
            const script = document.createElement('script');
            script.id = this.paragonScriptId;
            script.src = `https://cdn.useparagon.com/latest/sdk/index.js`;
            script.type = 'text/javascript';

            document.head.appendChild(script);
        },
        async getParagonUserToken() {
            const body = {
                venueId: this.venueId,
                type: 'venue'
            };

            try {
                const { data } = await this.$axios.post(
                    '/paragon/generate-user-token',
                    body
                );

                return data;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async paragonUser() {
          const { paragon } = window;

          const paragonUserToken = await this.getParagonUserToken();

          try {
            await paragon.authenticate(
                process.env.VUE_APP_PARAGON_PROJECT_ID,
                paragonUserToken
            );

            const paragonUser = paragon.getUser();

            return paragonUser.integrations;
          } catch (e) {
            return null
          }


        },
        async paragonIntegration(integration) {
            const { paragon } = window;

            this.isLoading = true;

            const paragonUserToken = await this.getParagonUserToken();

            try {
                await paragon.authenticate(
                    process.env.VUE_APP_PARAGON_PROJECT_ID,
                    paragonUserToken
                );

                paragon.connect(integration);
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'Something went wrong'
                });

                throw new Error(`PARAGON SDK ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        isSettingVisible(setting) {
            switch (setting.visibility) {
                case 'opat':
                    return this.isOrderAndPay;
                case 'takeaway':
                    return !this.isOrderAndPay;
                default:
                    return true;
            }
        },
        isIntegrationVisible(integration) {

          if (integration.internal) {
            return this.isFeatureAvailable(FeaturesEnum.INTERNAL);
          }

          switch (integration.visibility) {
            case 'opat':
              return this.isOrderAndPay;
            case 'takeaway':
              return !this.isOrderAndPay;
            default:
              return true;
          }
        },
        async toggleEnabled() {
            if (this.isLoading !== true) {
                this.isLoading = true;
                this.enabled = !this.enabled;

                let venue = {
                    enabled: this.enabled
                };

                try {
                    await this.$axios
                        .put('/venues/' + this.venue.id, venue)
                        .then(() => {})
                        .catch(e => {
                            throw new Error(`API ${e}`);
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                } catch (e) {
                    throw new Error('API Error: ', e);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async goLive() {
            if (this.isLoading !== true) {
                this.isLoading = true;
                this.venue.isLive = true;

                try {
                    await this.$axios
                        .put(`/venues/${this.venue.id}/live`)
                        .then(() => {})
                        .catch(e => {
                            throw new Error(`API ${e}`);
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                } catch (e) {
                    throw new Error('API Error: ', e);
                } finally {
                    this.isLoading = false;
                }
            }
        }
    }
};
</script>
